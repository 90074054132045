<template>
  <v-data-table
    id="base-table"
    fixed-header
    v-bind="$attrs"
    v-on="$listeners"
    mobile-breakpoint="0"
    calculate-widths
    :headers="columns"
    :height="height"
    :loading="loading"
    :items="items"
    :search="search"
    :no-data-text="$t('general_no_data_label')"
    :no-results-text="$t('general_no_results_label')"
    :footer-props="{
      itemsPerPageOptions: [15, 25, 50, 100, -1],
      showFirstLastPage: true,
      itemsPerPageText: $t('general_rows_per_pages_label'),
      itemsPerPageAllText: $t('general_items-per-page-all_label')
    }"
  >
    <!-- UPDATED AT FIELD -->
    <template #item.updated_at="{ value }">
      <span> {{ value | date }}</span>
    </template>

    <!-- CREATE AT FIELD -->
    <template #item.created_at="{ value }">
      <span> {{ value | date }}</span>
    </template>

    <!-- ACTIONS BUTTONS -->
    <template #item.action="{ item }">
      <div
        class="d-flex flex-row justify-end justify-md-center action-container"
      >
        <!-- EDIT -->
        <v-tooltip top v-if="buttons.edit">
          <template #activator="{ on }">
            <v-btn
              class="mr-2"
              icon
              color="#8990AD"
              v-on="on"
              @click="$emit('edit', item)"
            >
              <v-icon v-text="'mdi-pencil'" />
            </v-btn>
          </template>
          <span>{{ $t('general_edit_label') }}</span>
        </v-tooltip>

        <!-- DELETE -->
        <v-tooltip top v-if="buttons.delete">
          <template #activator="{ on }">
            <v-btn
              class="mr-2"
              icon
              color="#8990AD"
              v-on="on"
              @click="$emit('delete', item)"
            >
              <v-icon v-text="'mdi-delete'" />
            </v-btn>
          </template>
          <span>{{ $t('general_delete_label') }}</span>
        </v-tooltip>
        <slot name="custom-button" v-bind:item="item"></slot>
      </div>
    </template>

    <!-- HEADER -->
    <template #header.action>
      <span>{{ $t('general_actions_label') }}</span>
    </template>

    <!-- Bind slots to parent -->
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'BaseTable',
  props: {
    headers: {
      type: Array,
      required: true
    },
    section: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    totalRows: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '60vh'
    },
    buttons: {
      type: Object,
      default: () => ({})
    },
    search: String
  },
  computed: {
    columns() {
      const dataHeaders = this.headers.map(item => ({
        ...item,
        text: this.$t(item.text)
      }))

      if (this.buttons.edit || this.buttons.delete) {
        const item = dataHeaders.find(item => item.value === 'action')
        if (!item) {
          dataHeaders.push({
            text: this.$t('general_actions_label'),
            value: 'action',
            width: '10rem',
            align: 'center',
            sortable: false
          })
        }
      }
      return dataHeaders
    }
  }
}
</script>

<style lang="sass">
$alter-row-bg-color: #edf2f7

table
  thead tr
    th
      vertical-align: text-top
      color: #686F8D !important
      font-size: 14px !important
  tbody tr
    &:nth-of-type(odd)
      background-color: $alter-row-bg-color

    td:nth-child(1)
      position: sticky
      left: 0
      z-index: 1
      background: white

    &.v-data-table__selected
      td:nth-child(1)
        background-color: $alter-row-bg-color

  thead tr th:nth-child(1)
    position: sticky
    left: 0
    z-index: 1
    background: white

  tbody tr:nth-of-type(odd) td:nth-child(1):not(.total)
    background: $alter-row-bg-color
  tbody tr:hover:not(.total)
    background-color: rgb(238, 238, 238)
  tbody tr:hover td:nth-child(1):not(.total)
    background-color: rgb(238, 238, 238)

.v-data-table--fixed-header .v-data-table__wrapper table thead tr th:nth-child(1)
  z-index: 3
</style>
