var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"id":"base-table","fixed-header":"","mobile-breakpoint":"0","calculate-widths":"","headers":_vm.columns,"height":_vm.height,"loading":_vm.loading,"items":_vm.items,"search":_vm.search,"no-data-text":_vm.$t('general_no_data_label'),"no-results-text":_vm.$t('general_no_results_label'),"footer-props":{
    itemsPerPageOptions: [15, 25, 50, 100, -1],
    showFirstLastPage: true,
    itemsPerPageText: _vm.$t('general_rows_per_pages_label'),
    itemsPerPageAllText: _vm.$t('general_items-per-page-all_label')
  }},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
  var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value)))])]}},{key:"item.created_at",fn:function(ref){
  var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value)))])]}},{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-end justify-md-center action-container"},[(_vm.buttons.edit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD"},on:{"click":function($event){return _vm.$emit('edit', item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-pencil')}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('general_edit_label')))])]):_vm._e(),(_vm.buttons.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD"},on:{"click":function($event){return _vm.$emit('delete', item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-delete')}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('general_delete_label')))])]):_vm._e(),_vm._t("custom-button",null,{"item":item})],2)]}},{key:"header.action",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('general_actions_label')))])]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }