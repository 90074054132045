<template>
  <v-card
    rounded
    v-bind="$attrs"
    class="rounded-lg widget px-2 px-md-4"
    :class="paddingTop"
    :color="color"
    :elevation="elevation"
  >
    <v-card-title v-if="title">
      <span class="text-h6 font-weight-bold black--text">
        {{ $t(title) }}
      </span>
    </v-card-title>
    <v-card-text class="pa-0 px-md-4">
      <template v-if="loading">
        <Loading :loading="loading" />
      </template>
      <template v-else>
        <slot name="content"></slot>
      </template>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import Loading from './Loading'
export default {
  name: 'VWidget',
  components: { Loading },
  props: {
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'white'
    },
    dense: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: [Number, String],
      default: 2
    },
    paddingTop: {
      type: [String],
      default: 'pt-4'
    }
  }
}
</script>

<style lang="sass" scoped>
// Se tuvo que aplicar el !important xq vuetify viene con el !important para esa clase
.widget.v-sheet.elevation-2.v-card:not(.v-sheet--outlined)
  box-shadow: 0px 6px 10px #0000001A !important
</style>
